@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.secondary-color { 
  background-color: #283b4e;
}

.primary-color {
  background-color: #222831;
}

.border {
  border: 1px solid #385169;
}

.footer-links {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
 padding: 0px;
}

.footer-links-item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.footer-links-item:first-child {
  margin-left: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



