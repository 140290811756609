@import '~antd/dist/antd.css';

.riot-text {
    color: #ececec;
    font-family: 'Inter', sans-serif;
    font-size: calc(.4em + .4vmin);
    margin-bottom: 2em;
}

#hidden {
    color: #ececec;
    font-family: 'Inter', sans-serif;
}