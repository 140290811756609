@import '~antd/dist/antd.css';

#responsive-canvas {
    width: 85%;
    margin-top: 4px;
  }

div[disabled] {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed !important;
}

h4 {
    color: #ececec;
    font-family: 'Inter', sans-serif;
}

.spinner {
    position: absolute;

}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
