@import '~antd/dist/antd.css';


.title {
    color: #ececec;
    font-family: 'Inter', sans-serif;
    font-size: calc(3.75em + 2vmin);
    margin-bottom: -20px;
    display: inline-block;
}

.icon-top {
    max-width:100%;
    height:auto;
    margin-bottom: -63px;
    margin-top: -53px;
}

.subtitle {
    color: #ececec;
    font-family: 'Inter', sans-serif;
    font-size: calc(1.1em + .8vmin);
    margin-bottom: -.1em;
    margin-top: 6px;
}
